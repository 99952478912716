/**
 * Site header
 */
.site-header {
    border-top: 5px solid $accent-color;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
    @include media-query($on-palm) {
      float: none;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;
        text-decoration: none;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }

        &:hover {
            color: $accent-color;
        }
    }

    .rss-link {
        position: relative;
        top: -3px;
    }

    @include media-query($on-palm) {
        position: relative;
        float: none;
        background-color: $background-color;
        text-align: left;
        line-height: 40px;
        margin-bottom: 5px;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            width: auto;
            display: inline;
            padding: 2px 5px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 5px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit/2 0;

    p {
        font-size: 14px;
    }
}


/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0 $spacing-unit/2;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit/2;
        padding-bottom: $spacing-unit/2;
        &:not(:last-child) {
            border-bottom: 1px solid $grey-color-light;
        }
    }

    h2 {
        margin-bottom: 0;

        a {
            color: $text-color;
            text-decoration: none;

            &:hover {
                color: $accent-color;
            }
        }
    }
}

.post-meta {
    display: block;
    margin-bottom: $spacing-unit / 2;
    font-size: $small-font-size;
    color: $grey-color;

    a {
        color: $grey-color;
        text-decoration: none;

        &:hover {
            color: $accent-color;
        }
    }
}

.post-link {
    display: block;
    letter-spacing: -1px;
    font-size: 30px;
}

.tags-title {
    border-bottom: 1px solid $grey-color-light;
}

.inline-img-right {
    float: right;
    border: 1px solid $grey-color-light;
    padding: 2px;
    margin: 5px 0 5px 10px;
    width: 400px;
}

.inline-img-left {
    float: left;
    border: 1px solid $grey-color-light;
    padding: 2px;
    margin: 5px 10px 5px 0;
    width: 400px;
}

#blog-archives {
    h2 {
        position: relative;
        display: inline-block;
        float: left;
        clear: both;
    }
    article {
        position: relative;
        padding-left: 5em;
        padding-top: 13px;
        margin-top: 0;
        margin-bottom: 15px;
        time {
            position: absolute;
            top: 1em;
        }
        h1 {
            margin-bottom: 0;
            padding-left: 3.5em;
        }
    }
}

/**
 * Posts
 */
.post-header {
}

.post-title {
    display: block;
    margin-bottom: 0;
    font-size: 30px;
    letter-spacing: -1px;
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

p.post-summary {
    color: $text-color;
    font-size: 14px;
    margin-top: -$spacing-unit / 2;
}

a[rel=full-article] {
    background: $grey-color-light;
    display: inline-block;
    padding: .4em .8em;
    margin-right: .5em;
    text-decoration: none;
    color: $grey-color;
    &:hover {
      background: $grey-color;
      text-shadow: none;
      color: $grey-color-light;
    }
}

.pagination {
    position: relative;
    padding-top: $spacing-unit / 2;
    text-align: center;
    border-top: 1px solid $grey-color-light;
    a {
        text-decoration: none;
        &.prev {
            position: absolute;
            left: 0;
        }
        &.next {
            position: absolute;
            right: 0;
        }
    }
}
